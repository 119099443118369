<script>
</script>

<div class="container">
  <div class="pt-3 mb-4">
    <h2>核心理念</h2>
  </div>
  <div class="card shadow">
    <div class="card-body">
      <h3 class="card-title">
        堅定15黨
      </h3>
      <h5 class="card-subtitle mb-2">
        15就是15，不是貓，不是你老婆，更不是老婆貓！
      </h5>
      <p class="card-text text-muted">
        近來，在15的領地，不斷的有異教徒亂喊老婆貓，造成極大困擾，為了阻止老婆貓勢力擴張，堅定15黨應運而生。 <br />
        我們堅信，只有15說的才是真理，如果15說他不是老婆貓，那他就不是老婆貓，任何人皆不能違背15的意志。
      </p>
    </div>
  </div>

  <hr />

  <h3 class="mb-3">
    常見問題
  </h3>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">
        Q: 難道15永遠都不能當我老婆嗎
      </h4>
      <p class="card-text text-muted">
        並非如此，在尊重15意願的基礎下，如果15答應求婚了，便能讓15成為你的老婆。 <br />
        我黨的一切行為，皆基於15的想法，因此我們雖反對隨意亂喊老婆的行為，但對於向15求婚這類徵詢本人意願的行為，我們不會阻止，並且我們也鼓勵所有人在胡亂喊老婆之前，優先詢問15本人的意願，當個有禮貌的好野人。
      </p>
    </div>
  </div>
</div>

<style>
</style>

<script>
  import PageHead from './PageHead.svelte'
</script>

<PageHead title="SC 1+1" description="你SC，我就斗內" />

<div class="container">
  <div class="pt-3">
    <h3>SC 1+1 (即將開放)</h3>
    <div>有能力自行SC給15號的SC卡貴賓，將可申請 SC 1+1 服務</div>
    <div>只要您於持卡期間有自行SC給15號，並透過本服務登記，本黨將以您SC金額的一定比例，捐款至台灣石虎保育協會</div>
    <div>讓我們一起愛15，也愛護石虎</div>
    <br />
    <div>詳細申請方式、轉換比例、每月申請總額上限，將在服務開放後一併公佈</div>
  </div>
</div>

<style>
</style>
